.about_us {
    display: flex;
    padding: 100px;
    justify-content: space-between;
}

.about_img {
    width: 40%;
}

.about_img img {
    width: 100%;
    display: block;
    border-radius: 16px;
}

.about_content {
    width: 100%;
    text-align: center;
}

.about_content h6 {
    font-size: 18px;
    font-weight: 600;
    /* color: #db2824; */
    color: var(--header-color);
    margin-bottom: 10px;
}

.about_content h4 {
    font-size: 40px;
    margin-bottom: 10px;
    color: var(--head);
}

.about_content p {
    font-size: 18px;
}

@media (max-width:1140px) {
    .about_content h4 {
        font-size: 34px;
    }
}

@media (max-width:1000px) {
    .about_us {
        padding: 50px;
    }

}

@media (max-width:880px) {
    .about_us {
        flex-direction: column;
        align-items: center;
    }

    .about_img {
        width: 60%;
        margin-bottom: 30px;
    }

    .about_content {
        width: 80%;
        text-align: center;
    }

}

@media (max-width:640px) {
    .about_img {
        width: 80%;
    }

    .about_content {
        width: 100%;
    }
}

@media (max-width:480px) {
    .about_img {
        width: 100%;
    }

    .about_content {
        text-align: left;
    }

    .about_content h4 {
        font-size: 22px;
    }

    .about_content p {
        font-size: 16px;
    }

    .about_us {
        padding: 50px 20px;
    }
}