.packages {
    padding: 0 100px 100px 100px;
    text-align: center;
}

.packages h6 {
    font-size: 18px;
    font-weight: 600;
    /* color: #f30a0a; */
    color: var(--header-color);
    margin-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;
}

.packages h4 {
    font-size: 40px;
    margin-bottom: 30px;
    color: var(--head);
}

.package_list {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    gap: 40px;
}

.package_list .item {
    position: relative;
    width: 31%;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.package_list .item:hover {
    transform: scale(1.05);
}

.package_list .item .item_img {
    width: 100%;
    height: 250px;
    position: relative;
}

.package_list .item .item_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.package_list .item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
}

.package_list .item:hover .overlay {
    opacity: 1;
}

.package_list .item .overlay h6 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;
}

.package_list .item .overlay p {
    font-size: 14px;
    color: #ddd;
    box-sizing: border-box;
    word-break: break-word;
}

.package_list .item .item_content {
    padding: 20px;
}

.item_content_top {
    height: 110px;
}

.package_list .item .item_content p {
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;
}

.package_list .item .item_content span {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;

}

.lazy-load-image-background,
.blur l,
.azy-load-image-loaded {
    display: block !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width:1350px) {
    .package_list .item {
        width: 30%;
    }
}

@media (max-width:1000px) {
    .package_list .item {
        width: 45%;
    }
}

@media (max-width:740px) {
    .packages {
        padding: 0 50px 50px 50px;
    }

}

@media (max-width:600px) {
    .package_list .item {
        width: 100%;
    }

    .packages h4 {
        font-size: 30px;
    }
}

@media (max-width:480px) {

    .packages h4 {
        font-size: 24px;
    }

    .packages {
        padding: 0 20px 50px 20px;
    }
}

.book {
    border-radius: 6px;
    padding: 14px 20px;
    background-color: #128c7e;
    color: #fff;
    cursor: pointer;
    display: block;
    margin-top: 10px;
}