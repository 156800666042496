.footer {
    background-color: #212121;
    padding: 30px 0 20px;
}

.footer footer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #656565;
    padding: 0 80px 30px;
    flex-wrap: wrap;
}



@media (max-width: 480px) {
    .footer footer {
        padding: 0 20px 25px;
    }
}



.footer footer .contact_main {
    width: 30%;
}

.footer footer .main_social {
    width: 24%;
}

.footer footer .branch {
    width: 16%;
}

.footer footer .our_servi {
    width: 16%;
}


.footer footer ul img {
    width: 120px;
    margin-bottom: 10px;
}

@media (max-width: 1000px) {
    .footer footer .contact_main {
        width: 46%;
    }

    .footer footer .main_social {
        width: 46%;
    }

    .footer footer .branch {
        width: 46%;
    }

    .footer footer .our_servi {
        width: 46%;
    }
}


@media (max-width: 680px) {
    .footer footer .contact_main {
        width: 96%;
    }

    .footer footer .main_social {
        width: 96%;
    }

    .footer footer .branch {
        width: 96%;
    }

    .footer footer .our_servi {
        width: 96%;
    }
}

@media (max-width: 480px) {
    .footer footer ul {
        padding: 0;
    }
}

.footer footer ul h6 {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 24px;
}

.footer footer ul.our_servi li {
    cursor: pointer;
}

.footer footer ul li {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 12px;
    cursor: default;
}

.footer footer ul li a {
    text-decoration: none;
    color: #fff;
}

@media (max-width: 480px) {
    .footer footer ul li {
        font-size: 16px;
    }
}

.footer footer ul li:hover {
    color: #fcfafabe;
    transition: 0.2s;
}

.footer footer ul li span {
    color: var(--primary-color);
}


.footer footer ul.social {
    display: flex;
    gap: 16px;
    padding: 0;
    align-items: center;
    margin-top: 16px;
}

.footer footer ul.social li {
    margin-bottom: 0;
}

.footer footer ul.social li svg {
    font-size: 30px;
}

.footer footer ul.social li.insta svg {
    background: linear-gradient(90deg, #833AB4, #FD1D1D, #FCB045);
    border-radius: 4px;
    font-size: 26px;
}

.footer footer ul.social li.facebook svg {
    color: #1877F2;
}

.footer footer ul.social li.youtube svg {
    color: #FF0000;
}

.footer footer ul.social li:hover {
    transform: scale(1.2);
    transition: .3s ease-in-out;
}

.footer .footer_bottom {
    display: flex;
    justify-content: space-between;
    padding: 20px 80px 0;
}

@media (max-width: 640px) {
    .footer .footer_bottom {
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media (max-width: 480px) {
    .footer .footer_bottom {
        padding: 20px 25px;
    }
}

.footer .footer_bottom p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center;
}

@media (max-width: 480px) {
    .footer .footer_bottom p {
        font-size: 12px;
    }
}

.footer .footer_bottom p span {
    color: var(--primary-color);
    margin-right: 4px;
}