body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: #F3F3F3;

}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    border: none;
    outline: none;

}

ul {
    text-decoration: none;
    list-style: none;
}

:root {
    --head: #000000de;
    --header-color: #254c73;

}

html {
    font-size: 16px;
}
