.admin_page .head {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    background-color: #fff;
}

.total_container {
    padding: 80px;
}

.total_container h3 {
    font-size: 36px;
    margin-bottom: 30px;
}

.item_changeList {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.item_admin {
    width: 30%;
    background-color: #fff;
    text-align: left;
}

.item_imageChange {
    width: 100%;
    height: 250px;
}

.item_imageChange img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item_contentChange {
    padding: 20px;
}

.item_contentChange h6 {
    font-size: 18px;
    margin-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;
}

.item_contentChange p {
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
    word-break: break-word;
}

.buttonAll {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.add {
    border-radius: 32px;
    padding: 12px 30px;
    background-color: #0b560b;
    color: #fff;
    margin-bottom: 30px;
    cursor: pointer;
}

.edit {
    border-radius: 32px;
    padding: 12px 20px;
    border: 1px solid #000;
    color: #1b0dd6;
    cursor: pointer;
}

.delete {
    border-radius: 32px;
    padding: 12px 20px;
    background-color: #d51a17;
    color: #fff;
    cursor: pointer;
}


/* .item_imageChange_add {
    width: 100%;
    height: 250px;
} */


.item_imageChange_add {
    margin-bottom: 20px;
    position: relative;
}
.item_imageChange_add label {
    display: flex;
    gap: 5px;
}

/* Styling for the image preview */
.image_preview {
    margin-top: 10px;
}

.image_preview img {
    max-width: 100%;
    /* Make sure the image doesn't overflow its container */
    max-height: 200px;
    /* Set a maximum height to maintain aspect ratio */
    object-fit: cover;
    /* Ensure the image covers the container without distortion */
    border: 2px solid #ddd;
    /* Add a border for better visibility */
    border-radius: 8px;
    /* Optional: Add rounded corners */
}

.item_contentChange_add_section {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.add_container {
    width: 100%;
    /* display: flex;align-items: center;
    justify-content: center; */
}

.form_container_add_section {
    width: 350px;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 50px;
}

.packege_code {
    font-weight: 500;
    display: flex;
    gap: 10px;
    width: 100%;
}

.packege_code input {
    height: auto;
    font-weight: 700;
    width: 100%;
    color: #080808;
}


.add_header {
    width: 100%;
    display: flex;
    gap: 10px;
    font-weight: 700;

}

.add_header input {
    height: auto;
    /* font-weight: 700; */
    width: 100%;
}

.add_desc {
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    font-weight: 600;

}

.add_desc input {
    width: 100%;
}

.add_date {
    width: 100%;
    height: 30px;
    display: flex;
    gap: 10px;
    font-weight: 600;
}

.item_contentChange_add_section button {
    width: 100%;
    height: 30px;
    background-color: rgba(255, 68, 0, 0.918);
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    margin: auto;
}

@media (max-width:1070px) {
    .item_admin {
        width: 44%;
    }

}

@media (max-width:740px) {
    .total_container h3 {
        font-size: 28px;
    }
}

@media (max-width:640px) {
    .item_admin {
        width: 100%;
    }

}

@media (max-width:780px) {
    .total_container {
        padding: 20px;
    }
}





/* alert */


.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.alert_delete {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    width: 40%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slide-down 0.3s ease-out;
}

.alert_delete h5 {
    font-size: 18px;
    color: #d51a17;
    margin-bottom: 14px;
}

.alert_delete .verify {
    display: flex;
    gap: 18px;
    justify-content: center;
}

.alert_delete .verify button {
    border: 1px solid #d51a17;
    border-radius: 34px;
    color: #d51a17;
    height: 46px;
    width: 120px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    font-weight: 400;
}

.alert_delete .verify button:hover {
    background-color: #d52a27;
    color: #fff;
}

@keyframes slide-down {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width:700px) {
    .alert_delete {
        width: 50%;
    }
}

@media (max-width:560px) {
    .alert_delete {
        width: 64%;
    }
}