/* .gallery {
    padding: 100px;
}

.gallery h6 {
    font-size: 18px;
    font-weight: 600;
    color: #f30a0a;
    margin-bottom: 10px;
    text-align: center;
}

.gallery .gallery_list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.gallery .gallery_list .gallery_img {
    width: 30%;
    height: 200px;

}

.gallery .gallery_list .gallery_img img {
    display: block;
    width: 100%;
} */
/* 
.gallery {
    padding: 100px;
}

.gallery h6 {
    font-size: 18px;
    font-weight: 600;
    color: #7ab730;
    margin-bottom: 10px;
    text-align: center;
}

.gallery h4 {
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
}

.gallery .gallery_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
}

.gallery .gallery_list .gallery_img {
    overflow: hidden;
    position: relative;
}

.gallery .gallery_list .gallery_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width:640px) {
    .gallery {
        padding: 50px;
    }
} */


.gallery {
    padding: 100px;
}

.gallery h6 {
    font-size: 18px;
    font-weight: 600;
    color:var(--header-color);
    margin-bottom: 10px;
    text-align: center;
}

.gallery h4 {
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
    color: var(--head);
}

.gallery .gallery_slider .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery .gallery_slider .slick-list {
    margin: 0 -15px;
}

.gallery .gallery_slider .gallery_img {
    padding: 0 15px;
    overflow: hidden;
    position: relative;
}

.gallery .gallery_slider .gallery_img img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.slick-dots {
    bottom: -30px;
}

@media (max-width: 640px) {
    .gallery {
        padding: 50px;
    }

    .gallery .gallery_slider .gallery_img img {
        height: 150px;
    }
}

@media (max-width:480px) {
    .gallery h4 {
        font-size: 24px;
    }

}