.ourGuides {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.ourGuides h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--header-color);
    margin-bottom: 10px;
}

.ourGuides h4 {
    font-size: 40px;
    margin-bottom: 30px;
}

/* ------------------------------------------------------- */

.guide_container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
}

.guides {
    background-color: #fff;
    width: 250px;
}

.guide_img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guide_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
}

.guide_img img:hover {
    transform: scale(1.2);
    /* Zoom in slightly on hover */
}

.content_guide {

    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;


}

.content_guide span {
    font-size: 19px;
    font-weight: 600;

}

.content_guide p {
    font-size: 16px;

}

@media (max-width:760px) {
    .guides {
        width: 230px;
    }
}

@media (max-width:710px) {

    .guide_container {
        flex-direction: column;

    }

    .guides {
        width: 430px;
    }
}

@media (max-width:600px) {
    .ourGuides h4 {
        font-size: 30px;

    }
}

@media (max-width:500px) {

    .guides {
        width: 380px;
    }
}

@media (max-width:480px) {

    .ourGuides h4 {
        font-size: 24px;

    }
}

@media (max-width:470px) {


    .guides {
        width: 100%;

    }

    .ourGuides {
        padding: 20px;
    }
}