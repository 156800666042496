.spotlight {
    position: relative;
    background: url(../../assets/images/carousel-1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* height: calc(100vh - 80px); */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 1;
}

.spotlight::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.spotlight .content {
    width: 70%;
    padding-left: 80px;
    position: relative;
    z-index: 2;
}

.spotlight .content h5 {
    font-size: clamp(2rem, 5vw + 1rem, 4rem);
    font-weight: 600;
    color: white;
}

@media (max-width:1190px) {
    .spotlight .content {
        width: 90%;
    }
}



@media (max-width:790px) {
    .spotlight .content {
        width: 100%;
        padding: 0 40px;
    }
}



@media (max-width:640px) {
    .spotlight {
        background-position: center;
    }
}

@media (max-width:480px) {


    .spotlight .content {
        padding: 0 20px;
    }

}

.socila_top {
    position: fixed;
    /* bottom: 20px; */
    right: 20px;
    top: 110px;
    background-color: #254c73bd;
    z-index: 99;
    border-radius: 6px;
    padding: 14px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.whtsp {
    margin-bottom: 16px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.whtsp svg {
    color: green;
    font-size: 30px;
}

.phn {
    width: 40px;
    height: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.phn svg {
    font-size: 24px;
    color: green;
}