.login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;


}

.input_container {
    padding: 50px;
    width: 500px;
    background-color: #fff;
    border-radius: 16px;
}

.input_container h5 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.input_container input {
    width: 100%;
    background-color: #F3F3F3;
    display: block;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 16px;

}

.Login_btn {
    width: 70px;
    padding: 8px 12px;
    background-color: #128c7e;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    display: block;
    cursor: pointer;
    margin: 0 auto;
}

.Login_btn:hover {
    background-color: #128c7ed2;
    transition: 0.3s;

}

@media (max-width:480px) {
    .input_container {
        margin: 0 24px;
        padding: 50px 30px;
        width: 100%;
    }


    .Otp_input_container {
        gap: 8px !important;
    }

    .otp_container input {
        width: 40px !important;
        height: 40px !important;
        padding: 10px !important;
    }

}