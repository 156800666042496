header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 88%;
    padding: 0 100px;
    position: fixed;
    left: 0;
    top: 5px;
    z-index: 99;
    background-color: #fff;
    translate: 7%;
    border-radius: 40px;
    transition: backdrop-filter 0.3s ease;
}

header svg {
    display: none;
    font-size: 30px;
}

header h1 {
    width: 100px;
}

header h1 img {
    display: block;
    width: 100%;
}

header ul {
    display: flex;
    align-items: center;
    gap: 20px;
}

header ul li {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: var(--head);
}

header ul li:hover {
    color:  #7382a7;
}

header ul li .active {
    color:var(--header-color);
    color:  #7382a7;
}

@media (max-width: 1105px) {
    header ul li {
        font-size: 16px;
    }
}

@media (max-width: 1040px) {
    header {
        padding: 0 50px;
    }
}

@media (max-width: 930px) {
    header ul {
        display: none;
    }

    header svg {
        display: block;
    }

    .mobile-menu {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        top: 92px;
        right: 0;
        background-color: #fff;
        border-radius: 20px 20px 10px 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 500px;
        padding: 50px;
        height: 550px;
    }

    .mobile-menu li {
        padding: 10px 20px;
    }

    .mobile-menu li:hover {
        background-color: #f1f1f1;
    }
}

@media (max-width: 480px) {
    header {
        height: 70px;
        padding: 0 30px;
    }

    header h1 {
        width: 80px;
    }

    .mobile-menu {
        top: 70px;
    }
}