.otp {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Otp_input_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin: 0;
}

.otp_container {
    width: 450px;
    padding: 50px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.otp_container input {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    border: 1px solid rgb(141, 131, 131);
    padding: 20px;
}

#otp_text {
    font-size: 20px;
    letter-spacing: 1.5px;
}

.verify_btn {
    padding: 14px 30px;
    background-color: #128c7e;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.verify_btn:hover {
    background-color: #128c7ed2;
    transition: 0.3s;


}

@media (max-width:480px) {
    .otp_container {
        margin: 0 24px;
        padding: 50px 30px;
        width: 100%;
    }

}